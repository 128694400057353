<template>
  <div class="rightLink">
    <div class="bottomDiv" style="margin-top:20px">
      <div class="index_title" style="border-bottom: none">余额充值</div>
      <div class="formDiv" style="margin-left: 8vw;width:70vw; margin-bottom: 2px;">
        <el-form label-position="left" label-width="60px">
          <!-- <el-row :gutter="20">
            <el-col :span="6"> -->
              <el-form-item class="item1" label="编号:">
                <el-input
                  v-model="FormData.id"
                  placeholder="请输入编号"
                ></el-input>
              </el-form-item>
            <!-- </el-col>
            <el-col :span="6"> -->
              <el-form-item class="item1" label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                ></el-input>
              </el-form-item>
            <!-- </el-col>
            <el-col v-show="isadmin" :span="6"> -->
              <el-form-item v-model="isadmin" class="item1" label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                  <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                </el-select>
              </el-form-item>
            <!-- </el-col>
            <el-col :span="1"> -->
              <div class="BtnSearch" style="width: 30vw; margin: 5px 20vw;" @click="searchBtn()">查询</div>
            <!-- </el-col>
          </el-row> -->
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="500px"
          style="
            width: 90%; 
            margin: 20px auto;
            overflow-y: auto;
          "
        >
        <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <el-table-column label="电动车余额(元)" prop="balance" :formatter="rounding" />
          <el-table-column label="电动汽车余额(元)" prop="balance_new" :formatter="rounding" />
          <el-table-column label="注册时间" prop="last_login" />
         
          <el-table-column
            label="操作"
            width="90"
            align="center"
            fixed="right" 
          >
            <template #default="scope">
              <el-button round color='#42a7a9' plain size="mini" @click="charge(scope.$index, scope.row)">充值</el-button>

            </template>
          </el-table-column>
        </el-table>
        
        <div class="flex1">
          <p>电动车余额：<span>{{ tableData1.sum_balance }}</span></p>
            <p>电动汽车余额：<span>{{ tableData1.sum_balance_new }}</span></p>
            <p>总余额：<span>{{ tableData1.sum_money }}</span></p>
        </div>
        <!-- 充值弹窗 -->
        <el-dialog
          v-model="Dialog.editLevy"
          title="充值"
          width="80vw"
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="formDiv1">
            <el-form label-position="right" label-width="80px">
 
                  <el-form-item label="手机号:" style="width: 65vw;">
                    <el-input
                      v-model="Dialog.DialogData.phone"
                    ></el-input>
                  </el-form-item>
                
                  <el-form-item label="充值车型:" style="width: 65vw;">
                    <el-select style="width: 100%;" v-model="Dialog.DialogData.car" placeholder="请选择充电类型">
                      <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                    </el-select>
                  </el-form-item>

           
                  <el-form-item label="充值金额:"  style="width: 65vw;">
                    <el-input
                      v-model="Dialog.DialogData.charge_amount"
                    ></el-input>
                  </el-form-item>
             
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button type="primary" class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template>
        </el-dialog>
        <!-- 退费弹窗 -->
       
      </div>
    </div>

  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_user,charge_money,prohibit_user,operator_data } from "@/request/api";
import { ElMessage,ElMessageBox  } from "element-plus";
export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      //表单数据
      carList:[
          {
            value:2,
            label:'电动车'
          },
          {
            value:4,
            label:'电动汽车'
          }
        ],
        car:'',
      
      FormData: {
        operator_id : sessionStorage.getItem('userID')
      },
      isadmin:false,
      users:[],
      tableData: [],
      tableData1: {},
      
      loading: false,
       //   弹框
       Dialog: {
        editLevy: false,
        DialogData: {},
      },
       Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      
      //   分页
      currentPage:1,
      pageSize:20,
      total:400,
      checked:0,
      small:true,
    });
    const rounding=(row,column)=> {
      return parseFloat(row[column.property]?row[column.property]:'0').toFixed(2)
    }
    const formatDate =(row, column) => {
      // 获取单元格数据
      let data = row[column.property]
      if(data == null) {
          return null
      }
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    }
    const searchBtn = () => {
      getList();
    };



    
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog1.editLevy = false;
    };
    const submitDialog = () => {
      console.log("提交");
      submitData();
    };
    const submitDialog1 = () => {
      console.log("提交");
      submitData1();
    };

    const submitData = () => {
      const dataa = {
        phone : data.Dialog.DialogData.phone,
        charge_amount:data.Dialog.DialogData.charge_amount,
        type_id:data.Dialog.DialogData.car
      }
      console.log(dataa,'dataaaaaa');
      charge_money(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog()
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "充值成功",
              type: "success",
            });
            getList()
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }
      });
    };
    const submitData1 = () => {
      const dataa = {
        // phone : data.Dialog1.DialogData.phone,
        refund:data.Dialog1.DialogData.refund_amount,
        type_id:data.Dialog1.DialogData.car
      }
      re_money(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog()
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "退费成功",
              type: "success",
            });
            getList()
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }

      });
    };

    const forbidden = (index, row) => {
      ElMessageBox.confirm(
        '确定要禁用该用户?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
           prohibit_user(row).then((res) => {
            console.log(res);
            if (res) {
              data.loading = false;
              if (res.code == 200) {
                console.log('已禁用');
                row.tableData = res.data;
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("禁用失败");
            }
            getList()
          });
        })
        .catch(() => {
         
        })
      
    }
    
    const charge = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    }
    const refund = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
    }
    const getList = () => {
      console.log("用户查询");
      const dataa = data.FormData
      console.log(dataa,'data.FormData11');
   
      data.loading = true;
      sel_user(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
            data.tableData1 = res
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        console.log(res,'sssssss');
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
      }
      });
    };
    const handleSizeChange = (val)=>{
        data.pageSize = val
    }
    const handleCurrentChange = (val)=>{
        data.currentPage = val
        // 
    }
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      getUsername,
      forbidden,
      charge,
      refund,
      closeDialog,
      submitDialog,
      submitDialog1,
      submitData,
      submitData1,
      handleSizeChange,
      handleCurrentChange,
      rounding,
      formatDate
    };
  },
};
</script>

<style>

.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    margin-top: -10px;
}


.bottomDiv {
    height: 80%;
  }
  .statistics {
    display: flex;
    width: 90%;
    padding-top: 15px;
    /* background: red; */
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .statistics1 {
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .statistics1 > div > a {
    text-decoration: none;
    color: black;
  }
  .s_title {
    font-size: 14px;
  }
  .s_title .el-icon {
    float: right;
    color: red;
  }
  .p_bottom .el-icon {
    float: right;
    color: green;
  }
  .s_water {
    line-height: 40px;
    font-size: 19px;
    /* font-weight: bold; */
  }
  .s_profress {
    line-height: 25px;
  }


.BtnSearch{
  float: left;
  width: 60px;
  margin: 0 4px;
}
.formDiv1{
  margin-left: 4vw;
}
.item1{
  margin-bottom: 5px !important;
}
.flex1{
  display: flex;
  /* justify-content: space-between; */
  padding: 5px 10px;
  /* margin-top: -10px; */

}
.flex1 p{
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span{
  color: red;
  
}
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
</style>







